import * as React from "react";
import { Layout } from "../components/layout/Layout";

const NotFoundPage = () => {
  return (
    <Layout className="bg-tertiary">
      <div className="text-center position-absolute top-50 start-50 translate-middle">
        <h1>Work in progress...</h1>
        <p>Come back later!</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
